import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
let myModal : any;
export default function ModalViewAll(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value:"",
    index:"",
    demo_order:false
  });

  const [allow,setAllow] = useState("");
  const [desc,setDesc] = useState("")
  const [notes,setNotes] = useState([])
  const [all_deatils,setAllDetails] = useState([])

  const [note_id,setNoteId] = useState("")




  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setDesc(searchValue);
  }

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("view");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      setDesc("");
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      // console.log("ModelDeleteUserRoles On props.open :", props.data.value);
      // if(props.data.dm ==true){
      //   setAllow("on")
      // }else{
      //   setAllow("off")

      // }
      get_view()
      myModal = new bootstrap.Modal(
        document.getElementById("view"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  async function get_view() {
 console.log(props)
    let pass_data = {
    
      get: {

       id:props.id,
    
      }
    };
    let response: any = await api("/settings/superapp_user_device_all_details",pass_data);
    if (response.status_code == 200) {
      console.log(response)
      setAllDetails(response.response.data)
   
    }
  }


  async function Save() {

    let pass_data = {
    
      post: {

        notes: desc,
        user_id: props.data.value,
        response: note_id
    
      }
    };
    console.log(pass_data)
    let data_res = await api("/settings/update_user_cart_response", pass_data);
  console.log(data_res)
    if (data_res.status_code == 200) {
    let item = close_data;
    console.log(item)
    item.action = "save";
    item.index= props.data.index;
    item.demo_order=data_res.response.ecommerce_cart_response
    setDesc("");
    // item.value =  data_res.response.data.cod_allowed_all_users;
    SetCloseData(item);
  
    myModal.hide();
    }
  }


  const handleApplicationChange = (event: any) => {
    console.log(event.target.value)
    setNoteId(event.target.value)
  };

  return (
    <div
      className="modal fade"
      id="view"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
           All Details
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
          {all_deatils?.map((ord_ele: any, ord_index: any) => (
         <div className="card p-3 cursor">
                          <div className="row">
                          <div className="col-6">
    <p className="extra-small text-gray">App Version</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.app_version}
    </p>
  </div>

  <div className="col-6">
    <p className="extra-small text-gray">Date</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.time}
    </p>
  </div>

  <div className="col-6">
  <p className="extra-small text-gray">Platform API Level</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.platformApiLevel != null ? ord_ele.platformApiLevel : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">Platform Features</p>
  {ord_ele.platformFeatures != null ?
  <textarea 
    className="form-control pt-1 text-dark fw-bold" 
    readOnly 
    rows="4"
  >
 { ord_ele.platformFeatures}
  </textarea>:

 <p> -</p>
}
</div>

<div className="col-6">
  <p className="extra-small text-gray">Firebase App Instance ID</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.firebase_analytics_appInstanceId != null ? ord_ele.firebase_analytics_appInstanceId : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">Firebase Session ID</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.firebase_analytics_sessionId != null ? ord_ele.firebase_analytics_sessionId : "-"}
  </p>
</div>


<div className="col-6">
  <p className="extra-small text-gray">Max Memory</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.maxMemory != null ? ord_ele.maxMemory : "-"}
  </p>
</div>
<div className="col-6">
  <p className="extra-small text-gray">OS Build ID</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.osBuildId != null ? ord_ele.osBuildId : "-"}
  </p>
</div>
<div className="col-6">
  <p className="extra-small text-gray">OS Name</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.osName != null ? ord_ele.osName : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">OS Version</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.osVersion != null ? ord_ele.osVersion : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">OS Internal Build ID</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.osInternalBuildId != null ? ord_ele.osInternalBuildId : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">Total Memory</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.totalMemory != null ? ord_ele.totalMemory : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">Uptime</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.uptime != null ? ord_ele.uptime : "-"}
  </p>
</div>

<div className="col-6">
    <p className="extra-small text-gray">Browser</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.browser !== "" ? ord_ele.browser : "-"}
    </p>
  </div>
  <div className="col-6">
    <p className="extra-small text-gray">Browser Version</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.browser_version !== "" ? ord_ele.browser_version : "-"}
    </p>
  </div>
  <div className="col-6">
    <p className="extra-small text-gray">Google Analytics Client ID</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.google_analytics_client_id !== null ? ord_ele.google_analytics_client_id : "-"}
    </p>
  </div>
  <div className="col-6">
    <p className="extra-small text-gray">Meta Pixel Client ID</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.metaPixelClientId !== null ? ord_ele.metaPixelClientId : "-"}
    </p>
  </div>
  <div className="col-6">
    <p className="extra-small text-gray">Referrer</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.referrer !== "" ? ord_ele.referrer : "-"}
    </p>
  </div>
  <div className="col-6">
    <p className="extra-small text-gray">Robot</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.robot !== "" ? ord_ele.robot : "-"}
    </p>
  </div>
  <div className="col-6">
    <p className="extra-small text-gray">User Agent</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.user_agent !== "" ? ord_ele.user_agent : "-"}
    </p>
  </div>
  <div className="col-6">
    <p className="extra-small text-gray">Web Platform</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.web_platform !== null ? ord_ele.web_platform : "-"}
    </p>
  </div>



                             </div> 
                             </div> 

          ))}
          </div>
          <div className="modal-footer">
            {/* <button
              type="button"
              className="btn btn-success"
            
              onClick={
                // myModal.hide();
                Save}
                  // data-bs-dismiss="modal"
            >
              Save
            </button> */}
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
