import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { DataProvider } from "./src/utils/Context/DataContext";
//css
import "./src/assets/libraries/bootstrap/css/bootstrap.min.css";
import "./src/assets/libraries/fontawesome/css/all.min.css";
import "./src/assets/css/style.css";
//js
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/js/bootstrap.bundle.js";
import en from "./src/utils/locales/en.json";
import Dashboard from "./src/pages/Dashboard/Dashboard";
import AdminLayout from "./src/components/AdminLayout/AdminLayout";
import Orders from "./src/pages/Orders/OutletOrders";
import OrderDetails1 from "./src/pages/Orders/OutletOrderDetails1";
import DeliveryCompanyPartner from "./src/pages/DeliveryCompanyPartner/DeliveryCompanyPartner";
import DeliveryPartnerRequest from "./src/pages/DeliveryPartnerRequest/DeliveryPartnerRequest";
import Map from "./src/pages/Map/Map";
import Gallery from "./src/pages/Gallery/Gallery";
import Categories from "./src/pages/Categories/Categories";
import CreateCategory from "./src/pages/Categories/CreateCategory";
import EditCategory from "./src/pages/Categories/EditCategory";
import ProductUpdateRequest from "./src/pages/ProductUpdateRequest/ProductUpdateRequest";
import ProductSpecificationUpdate from "./src/pages/ProductSpecificationUpdate/ProductUpdateRequest";
import Payments from "./src/pages/Pyments/Payments";
import Payment from "./src/pages/Payment/Payment";
import SellerUserRole from "./src/pages/SellerUserRole/SellerUserRole";
import PickupDropCartDetails from "./src/pages/CartDetails/PickupDropCartDetails";
import OrderDetailsNew from "./src/pages/Orders/OutletOrderDetailsNew";
import ProductNameDescUpdateRequest from "./src/pages/ProductNameDescUpdateRequest/ProductNameDescUpdateRequest";
import SellerBrands from "./src/pages/SellerBrands/SellerBrands";
import BrandDetails from "./src/pages/SellerBrands/BrandDetails";
import OutletActivationRequest from "./src/pages/OutletActivationRequest/OutletActivationRequest";
import SellerUserRolesAdd from "./src/pages/SellerUserRole/SellerUserRolesAdd";
import CartDetails from "./src/pages/CartDetails/CartDetails";
import Cart from "./src/pages/Cart/Cart";

import SettingPage from "./src/pages/SettingPage/SettingPage";
import SuperappUser from "./src/pages/SuperappUsers/SuperappUsers";
import QrCodeList from "./src/pages/QrCode/QrCodeList";
import OrderStatistics from "./src/pages/OrderStatistics/OrderStatistics";
import DeliveryPartnerCodSettlement from "./src/pages/DeliveryPartnerCodSettlement/DeliveryPartnerCodSettlement";
import DeliveryPartnerOrders from "./src/pages/DeliveryPartnerOrders/DeliveryPartnerOrders";
import SellerSettlements from "./src/pages/SellerSettlements";
import MakeSettlement from "./src/pages/SellerSettlements/MakeSettlement";
import { AppConfig } from "./src/config";
import UserAverageValueStatistics from "./src/pages/UserAverageValueStatistics/UserAverageValueStatistics";
import UserTotalOrderStatistics from "./src/pages/UserTotalOrderStatistics/UserTotalOrderStatistics";
import QrStatistics from "./src/pages/QrStatistics/QrStatistics";
import DeliveryDistanceStatistics from "./src/pages/DeliveryDistanceStatistics/DeliveryDistanceStatistics";
import BrandOutlets from "./src/pages/BrandOutlets/BrandOutlets";
import Rewards from "./src/pages/Rewards/Rewards";
import SellerTransaction from "./src/pages/Rewards/sellerTransaction";
import DynamicDatas from "./src/pages/DynamicDatas/DynamicDatas";
loadScript(
  "https://maps.googleapis.com/maps/api/js?key=" +
    AppConfig.api +
    "&v=weekly&libraries=places,geometry"
);

function loadScript(url: any) {
  console.log("load script ");
  var index = window.document.getElementsByTagName("script")[0];
  var script = window.document.createElement("script");
  script.id = "google_map";
  script.src = url;
  // script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default function App() {
  console.log("App before mount :");

  useEffect(() => {
    console.log("App On mount :");
    if (localStorage.getItem("language_data") == undefined) {
      localStorage.setItem("language_data", JSON.stringify(en));
    }
  }, []);

  return (
    <DataProvider>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={RouteAdapter}>
          <Routes>
            <Route path="/" element={<AdminLayout />}>
              <Route index element={<Navigate to="dashboard" />} />
              <Route path="dashboard" element={<Dashboard />} />

              <Route path="orders" element={<Orders />} />
              <Route path="orders_old/:ord_id" element={<OrderDetails1 />} />
              <Route path="orders/:ord_id" element={<OrderDetailsNew />} />

              <Route
                path="delivery_company"
                element={<DeliveryCompanyPartner />}
              />

              <Route
                path="delivery_partner_cod_settlement"
                element={<DeliveryPartnerCodSettlement />}
              />

              <Route
                path="delivery_partner_request"
                element={<DeliveryPartnerRequest />}
              />

              <Route path="map" element={<Map />} />
              <Route path="gallery" element={<Gallery />} />
              <Route
                path="product_name_desc_request"
                element={<ProductNameDescUpdateRequest />}
              />
              <Route
                path="product_update_request"
                element={<ProductUpdateRequest />}
              />
              <Route
                path="product_Specificaton_update_request"
                element={<ProductSpecificationUpdate />}
              />

              <Route path="categories" element={<Categories />} />
              <Route
                path="categories/add_category/:cat_id/:sec_id"
                element={<CreateCategory />}
              />
              <Route
                path="/categories/edit_category/:cat_id"
                element={<EditCategory />}
              />
              <Route path="qr_code" element={<QrCodeList />} />
              <Route path="qr_statistics" element={<QrStatistics />} />
              <Route path="dynamic_data" element={<DynamicDatas />} />

              <Route path="brand_outlets" element={<BrandOutlets />} />


              <Route path="order_statistics" element={<OrderStatistics />} />
              <Route path="users_aov_statistics" element={<UserAverageValueStatistics />} />
              <Route path="users_total_order_statistics" element={<UserTotalOrderStatistics />} />
              <Route path="total_order_delivery_distance_statistics" element={<DeliveryDistanceStatistics />} />






              <Route path="payments" element={<Payments />} />
              <Route path="cart_details" element={<CartDetails />} />
              <Route
                path="cart"
                element={<Cart />}
              />
              <Route
                path="pickup_drop_cart_details"
                element={<PickupDropCartDetails />}
              />

              <Route path="payment" element={<Payment />} />
              <Route path="settings" element={<SettingPage />} />
              <Route path="superapp_users" element={<SuperappUser />} />
              <Route path="delivery_partner_orders" element={<DeliveryPartnerOrders/>} />
              <Route path="delivery_partner_orders/:id" element={<DeliveryPartnerOrders/>} />

              <Route path="seller_settlements" element={<SellerSettlements />} />
              <Route path="make_settlement" element={<MakeSettlement />} />

              <Route path="seller_brands" element={<SellerBrands />} />
              <Route
                path="seller_brands/:brand_id"
                element={<BrandDetails />}
              />
              <Route
                path="outlet_activation_requests"
                element={<OutletActivationRequest />}
              />

              <Route path="seller_user_roles" element={<SellerUserRole />} />
              <Route
                path="seller_user_roles/:page_type/:role_type/:role_id"
                element={<SellerUserRolesAdd />}
              />
                <Route
                path="reward"
                element={<Rewards />
                  
                }
              />
                           <Route
                path="rp_transaction"
                element={<SellerTransaction/>
                  
                }
              />
            </Route>
          </Routes>
        </QueryParamProvider>
      </BrowserRouter>
    </DataProvider>
  );
}

function RouteAdapter({ children }: any) {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
}
