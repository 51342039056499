import React ,{useState,useEffect} from 'react'
import { useOutletContext } from "react-router-dom";
import { api } from "../../utils/Api";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

function BrandOutlets() {

  const [statistics_data, SetStatisticsData]: any = useState([]);
  const OutletContext = useOutletContext();
  const [timeoutId, setTimeoutId] = useState(null);

  const [selectedChannel, setSelectedChannel] = useState("2");
  const [sort, setSort] = useState("");
  const [filter_data, setFilterData] = useState("");
  const [showDeletedOutlets, setShowDeletedOutlets] = useState(false);
  const [showDeletedBrands, setShowDeletedBrands] = useState(false);

    const [startDateShow, setStartDateShow] = useState("");
  const [endDateShow, setEndDateShow] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [width, SetWidth] = useState(innerWidth);

  useEffect(() => {
   
    let send_data = {
      startDate:startDate,
      endDate:endDate,
      page_no: 1,
      search:filter_data
    };
    get_overview(send_data);
SetPageNo(1)
    // get_outlets_and_channels();
  }, [sort,showDeletedBrands,showDeletedOutlets,selectedChannel]);


  
  function convertDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = '00'; // Set seconds to 00
    
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const handleOutletCheckboxChange = (event) => {
    setShowDeletedOutlets(event.target.checked);
    if (event.target.checked) {
      console.log("Showing deleted outlets");
      // Add logic to show deleted outlets
    } else {
      console.log("Hiding deleted outlets");
      // Add logic to hide deleted outlets
    }
  };

  // Handler for brands checkbox
  const handleBrandCheckboxChange = (event) => {
    setShowDeletedBrands(event.target.checked);
    if (event.target.checked) {
      console.log("Showing deleted brands");
      // Add logic to show deleted brands
    } else {
      console.log("Hiding deleted brands");
      // Add logic to hide deleted brands
    }
  };
  async function get_overview(get_data: any) {

 
     let pass_data = {
       get: {
         filter_from:get_data.startDate,
         filter_to: get_data.endDate,
         order_by: sort,

search:get_data.search,
filter_deleted_outlet: showDeletedOutlets?  "1":"0",
filter_deleted_brand: showDeletedBrands?  "1" : "0",
channel:selectedChannel,
        page: get_data.page_no,
       },
     };
     let response: any = await api("/sellers/list", pass_data);
     console.log("/sellers/list :", response.response);
     if (response.status_code == 200) {
       if (response.response.hasOwnProperty("data")) {

        
        //  let statistics_data = response.response.data;
 
         if (get_data.load_more) {
          SetStatisticsData((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetStatisticsData(response.response.data);

        }
    
 
  
       }
  
      // if(!get_data.hasOwnProperty("type")){
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
 
      
     }
   }

   const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call your API with the search value
      let send_data = {
      startDate:startDate,
      endDate:endDate,
      page_no: 1,
      search:   searchValue
    };
    get_overview(send_data);
      SetPageNo(1);
    }, 1000); // 5 milliseconds delay

    setTimeoutId(newTimeoutId);
  };

  const handleChannelChange = (event) => {
    setSelectedChannel(event.target.value);
  };

  return (
    <div>
    <div
    className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
    style={OutletContext == false ? { width: width } : {}}
  >
        <h6 className="fs-5  mb-0">Brand Outlet</h6>
        <div className="ms-lg-auto">  
        <div className="d-flex align-items-center">
        <div>
      <div className='bold mt-2 mb-2'>Select Channel</div>
      {/* Radio for 1SuperApp Own Stores */}
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="channel"
          id="ownStores"
          value="1"
          checked={selectedChannel === "1"}
          onChange={handleChannelChange}
        />
        <label className="form-check-label" htmlFor="ownStores">
          1SuperApp Own Stores
        </label>
      </div>

      {/* Radio for 1SuperApp Market Place (initially checked) */}
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="channel"
          id="marketPlace"
          value="2"
          checked={selectedChannel === "2"}
          onChange={handleChannelChange}
        />
        <label className="form-check-label" htmlFor="marketPlace">
          1SuperApp Market Place
        </label>
      </div>
    </div>
 <div className='ms-auto'>
                        <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="showDeletedOutlets"
          checked={showDeletedOutlets}
          onChange={handleOutletCheckboxChange}
        />
        <label className="form-check-label" htmlFor="showDeletedOutlets">
          Show Deleted Outlets
        </label>
      </div>

      {/* Checkbox for Show Deleted Brands */}
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="showDeletedBrands"
          checked={showDeletedBrands}
          onChange={handleBrandCheckboxChange}
        />
        <label className="form-check-label" htmlFor="showDeletedBrands">
          Show Deleted Brands
        </label>
      </div>

      </div>

        </div>
        <div className="input-icons form-group p-2">
                            <input
                                type="text"
                                className="form-control search-bar p-0"
                                placeholder="Search here ..."
                                onChange={handleInputChange}
                            />
                            <div className="cursor px-2 border-left">

                            </div>
                        </div>
        </div>
  

    

        </div>

        
        <div className="container mt-5">
       
      
      <InfiniteScroll
            dataLength={statistics_data.length}
            next={() => {
              let send_data = {
                startDate:startDate,
                endDate:endDate,
                load_more: true,

                page_no: page_no,
      search:filter_data

              };
              get_overview(send_data);
            }}
            hasMore={next_page}
            loader={
              <div className="center m-5">
                <div className="lds-dual-ring"></div>
                <p className=" small ps-2">Just a second ...</p>
              </div>
            }
          >
            <div className="table-responsive" >
      <table class="table table-striped table-bordered">
            <thead class="table-dark">
                <tr style={{ cursor: 'pointer' }}>
                <th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_brand_id' ? 'desc_brand_id' : 'asc_brand_id';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Brand ID</div>
    {sort === 'desc_brand_id' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_brand_id' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>
             
           
<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_brand_created_date' ? 'desc_brand_created_date' : 'asc_brand_created_date';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Brand Created Date</div>
    {sort === 'desc_brand_created_date' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_brand_created_date' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_brand_name' ? 'desc_brand_name' : 'asc_brand_name';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Brand Name</div>
    {sort === 'desc_brand_name' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_brand_name' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_id' ? 'desc_outlet_id' : 'asc_outlet_id';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet ID</div>
    {sort === 'desc_outlet_id' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_id' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_created_date' ? 'desc_outlet_created_date' : 'asc_outlet_created_date';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet Created Date</div>
    {sort === 'desc_outlet_created_date' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_created_date' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_name' ? 'desc_outlet_name' : 'asc_outlet_name';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet Name</div>
    {sort === 'desc_outlet_name' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_name' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_slug' ? 'desc_outlet_slug' : 'asc_outlet_slug';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet Slug</div>
    {sort === 'desc_outlet_slug' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_slug' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>
<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_channel_name' ? 'desc_channel_name' : 'asc_channel_name';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Channel Name</div>
    {sort === 'desc_channel_name' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_channel_name' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_approved' ? 'desc_approved' : 'asc_approved';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Approved</div>
    {sort === 'desc_approved' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_approved' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>
            

               
<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_available' ? 'desc_outlet_available' : 'asc_outlet_available';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet Available</div>
    {sort === 'desc_outlet_available' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_available' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_shop_location' ? 'desc_shop_location' : 'asc_shop_location';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Shop Location</div>
    {sort === 'desc_shop_location' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_shop_location' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_total_shop_products' ? 'desc_total_shop_products' : 'asc_total_shop_products';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Total Shop Products</div>
    {sort === 'desc_total_shop_products' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_total_shop_products' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_total_shop_product_groups' ? 'desc_total_shop_product_groups' : 'asc_total_shop_product_groups';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Total Shop Product Groups</div>
    {sort === 'desc_total_shop_product_groups' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_total_shop_product_groups' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_total_call_reminder_numbers' ? 'desc_total_call_reminder_numbers' : 'asc_total_call_reminder_numbers';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Total Call Reminder Numbers</div>
    {sort === 'desc_total_call_reminder_numbers' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_total_call_reminder_numbers' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>
<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_unavailable' ? 'desc_outlet_unavailable' : 'asc_outlet_unavailable';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet Unavailable</div>
    {sort === 'desc_outlet_unavailable' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_unavailable' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_auto_available' ? 'desc_outlet_auto_available' : 'asc_outlet_auto_available';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet Auto Available</div>
    {sort === 'desc_outlet_auto_available' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_auto_available' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_force_available' ? 'desc_outlet_force_available' : 'asc_outlet_force_available';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet Force Available</div>
    {sort === 'desc_outlet_force_available' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_force_available' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_auto_available_time' ? 'desc_outlet_auto_available_time' : 'asc_outlet_auto_available_time';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet Auto Available Time</div>
    {sort === 'desc_outlet_auto_available_time' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_auto_available_time' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_force_available_off' ? 'desc_outlet_force_available_off' : 'asc_outlet_force_available_off';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet Force Available Off</div>
    {sort === 'desc_outlet_force_available_off' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_force_available_off' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_force_available_on' ? 'desc_outlet_force_available_on' : 'asc_outlet_force_available_on';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet Force Available On</div>
    {sort === 'desc_outlet_force_available_on' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_force_available_on' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>
<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_lat' ? 'desc_lat' : 'asc_lat';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Lat</div>
    {sort === 'desc_lat' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_lat' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_lon' ? 'desc_lon' : 'asc_lon';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Lon</div>
    {sort === 'desc_lon' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_lon' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_map_address' ? 'desc_map_address' : 'asc_map_address';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Map Address</div>
    {sort === 'desc_map_address' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_map_address' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_door_no' ? 'desc_door_no' : 'asc_door_no';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Door No</div>
    {sort === 'desc_door_no' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_door_no' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_address' ? 'desc_address' : 'asc_address';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Address</div>
    {sort === 'desc_address' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_address' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_city' ? 'desc_city' : 'asc_city';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>City</div>
    {sort === 'desc_city' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_city' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_pincode' ? 'desc_pincode' : 'asc_pincode';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Pincode</div>
    {sort === 'desc_pincode' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_pincode' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_mobile' ? 'desc_mobile' : 'asc_mobile';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Mobile</div>
    {sort === 'desc_mobile' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_mobile' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_whatsapp' ? 'desc_whatsapp' : 'asc_whatsapp';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Whatsapp</div>
    {sort === 'desc_whatsapp' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_whatsapp' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_email' ? 'desc_email' : 'asc_email';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Email</div>
    {sort === 'desc_email' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_email' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_website' ? 'desc_website' : 'asc_website';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Website</div>
    {sort === 'desc_website' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_website' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_gstin' ? 'desc_gstin' : 'asc_gstin';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>GSTIN</div>
    {sort === 'desc_gstin' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_gstin' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_approx_preparation_duration' ? 'desc_approx_preparation_duration' : 'asc_approx_preparation_duration';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Approx Preparation Duration</div>
    {sort === 'desc_approx_preparation_duration' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_approx_preparation_duration' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_cod' ? 'desc_cod' : 'asc_cod';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>COD</div>
    {sort === 'desc_cod' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_cod' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>
<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_outlet_deleted' ? 'desc_outlet_deleted' : 'asc_outlet_deleted';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Outlet Deleted</div>
    {sort === 'desc_outlet_deleted' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_outlet_deleted' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_brand_deleted' ? 'desc_brand_deleted' : 'asc_brand_deleted';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Brand Deleted</div>
    {sort === 'desc_brand_deleted' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_brand_deleted' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_country_name' ? 'desc_country_name' : 'asc_country_name';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>Country Name</div>
    {sort === 'desc_country_name' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_country_name' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>

<th
  scope="row"
  onClick={() => {
    const newSort = sort === 'asc_state_name' ? 'desc_state_name' : 'asc_state_name';
    setSort(newSort);
  }}
>
  <div className="d-flex">
    <div>State Name</div>
    {sort === 'desc_state_name' ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === 'asc_state_name' ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : (
      <div />
    )}
  </div>
</th>
              
              
                </tr>
            </thead>
       
            <tbody>
           
              {statistics_data?.map((ele,i)=>(
                <tr>
                  <td>{ele.brand_id}</td>

                  <td>{ele.brand_created_date}</td>
<td>{ele.brand_name}</td>
<td>{ele.outlet_id}</td>
<td>{ele.outlet_created_date}</td>
<td>{ele.outlet_name}</td>
<td>{ele.outlet_slug}</td>
<td>{ele.channel_name}</td>
<td>{ele.approved == "1" ?"Yes":"No"}</td>
<td>{ele.outlet_available == "1" ?"Yes":"No"}</td>
<td>{ele.shop_location == "1" ?"Yes":"No"}</td>
<td>{ele.total_shop_products}</td>
<td>{ele.total_shop_product_groups}</td>
<td>{ele.total_call_reminder_numbers}</td>
<td>{ele.outlet_unavailable == "1" ?"Yes":"No"}</td>
<td>{ele.outlet_auto_available == "1" ?"Yes":"No"}</td>
<td>{ele.outlet_force_available == "1" ?"Yes":"No"}</td>
<td>{ele.outlet_auto_available_time}</td>
<td>{ele.outlet_force_available_off == "1" ?"Yes":"No"}</td>
<td>{ele.outlet_force_available_on == "1" ?"Yes":"No"}</td>


<td>{ele.lat}</td>
  <td>{ele.lon}</td>
  <td>{ele.map_address}</td>
  <td>{ele.door_no}</td>
  <td>{ele.address}</td>
  <td>{ele.city}</td>
  <td>{ele.pincode}</td>
  <td>{ele.mobile}</td>
  <td>{ele.whatsapp}</td>
  <td>{ele.email}</td>
  <td>{ele.website}</td>
  <td>{ele.gstin}</td>
  <td>{ele.approx_preparation_duration}</td>
  <td>{ele.cod == "1" ?"Yes":"No"}</td>
  <td>{ele.outlet_deleted == "1" ?"Yes":"No"}</td>
  <td>{ele.brand_deleted == "1" ?"Yes":"No"}</td>
  <td>{ele.country_name}</td>
  <td>{ele.state_name}</td>
                   
       
                </tr>
                  ))}
            </tbody>
     
        </table>
        
        </div>
        </InfiniteScroll>

        </div>

</div>
  )
}

export default BrandOutlets